var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-layout',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"debug",fn:function(){return [_c('fw-panel-info',{attrs:{"label":"Absence (raw)"}},[_c('json-viewer',{attrs:{"value":{ absence: _vm.absence }}})],1),_c('fw-panel-info',{attrs:{"label":"Justification (raw)"}},[_c('json-viewer',{attrs:{"value":{ justification: _vm.justification }}})],1)]},proxy:true},(_vm.justification.status)?{key:"header-nav",fn:function(){return [_c('fw-tag',{attrs:{"size":"sm","type":_vm.statusTagType,"custom-class":"px-3"}},[(!['draft', 'closed'].includes(_vm.justification.status))?_c('span',{staticClass:"font-regular opacity-70 mr-1"},[_vm._v(_vm._s(_vm.$t('status.label')))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(`status.${_vm.justification.status}`))+" ")])]},proxy:true}:null,{key:"header-toolbar",fn:function(){return [_c('JustificationActions',{attrs:{"justification":_vm.justification,"validations":{
        canEdit: _vm.canEdit,
        canSubmit: _vm.canSubmit,
        canDelete: _vm.canDelete
      }},on:{"submit":_vm.confirmSubmitJustification,"delete":_vm.confirmDeleteJustification}})]},proxy:true},{key:"main-sidebar",fn:function(){return [_c('SidebarJustification',{attrs:{"section":_vm.section}})]},proxy:true},{key:"main-content",fn:function(){return [_c('PanelJustification',{attrs:{"justification":_vm.justification,"absence":_vm.absence,"users":_vm.users,"comments":_vm.comments,"loading":_vm.loading,"saving-data":_vm.savingData,"saving-data-error":_vm.savingDataError,"validations":{
        canComment: _vm.canComment,
        canReview: false,
        canManagerReview: false,
        canClose: false,
        canEdit: _vm.canEdit,
        canSubmit: _vm.canSubmit,
        canDelete: _vm.canDelete
      }},on:{"update:justification":function($event){_vm.justification=$event},"update:absence":function($event){_vm.absence=$event},"update:users":function($event){_vm.users=$event},"update:comments":function($event){_vm.comments=$event},"save":_vm.saveJustification}})]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }