<template>
  <fw-layout :loading="loading">
    <template #debug>
      <fw-panel-info label="Absence (raw)">
        <json-viewer :value="{ absence }"></json-viewer>
      </fw-panel-info>
      <fw-panel-info label="Justification (raw)">
        <json-viewer :value="{ justification }"></json-viewer>
      </fw-panel-info>
    </template>

    <template v-if="justification.status" #header-nav>
      <fw-tag size="sm" :type="statusTagType" custom-class="px-3">
        <span v-if="!['draft', 'closed'].includes(justification.status)" class="font-regular opacity-70 mr-1">{{
          $t('status.label')
        }}</span>
        {{ $t(`status.${justification.status}`) }}
      </fw-tag>
    </template>

    <template #header-toolbar>
      <JustificationActions
        :justification="justification"
        :validations="{
          canEdit: canEdit,
          canSubmit: canSubmit,
          canDelete: canDelete
        }"
        @submit="confirmSubmitJustification"
        @delete="confirmDeleteJustification"
      />
    </template>

    <template #main-sidebar>
      <SidebarJustification :section="section" />
    </template>

    <template #main-content>
      <PanelJustification
        :justification.sync="justification"
        :absence.sync="absence"
        :users.sync="users"
        :comments.sync="comments"
        :loading="loading"
        :saving-data="savingData"
        :saving-data-error="savingDataError"
        :validations="{
          canComment: canComment,
          canReview: false,
          canManagerReview: false,
          canClose: false,
          canEdit: canEdit,
          canSubmit: canSubmit,
          canDelete: canDelete
        }"
        @save="saveJustification"
      />
    </template>
  </fw-layout>
</template>

<script>
import SidebarJustification from '@/components/sidebars/SidebarJustification'
import JustificationActions from '@/components/header/JustificationActions'
import PanelJustification from '@/components/panels/PanelJustification'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    SidebarJustification,
    JustificationActions,
    PanelJustification
  },

  data() {
    return {
      loading: true,
      absence: {},
      files: [],
      comments: [],
      users: {},
      justification: {
        motive: null,
        dates: [
          {
            startAt: null,
            endAt: null
          }
        ],
        startTime: null,
        endTime: null,
        relativeType: null,
        dateOfDeath: null,
        option: null,
        optionDescription: null,
        availableHours: null,
        hoursToUse: null,
        description: null,
        files: []
      },
      savingData: false,
      savingDataError: false,
      canSubmit: false,
      canDelete: false,
      canEdit: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    justificationKey() {
      return this.$route.params.key
    },

    section() {
      return this.$route.params.section
    },

    statusTagType() {
      let type = 'medium'
      if (this.justification.status == 'draft') {
        type = 'orange'
      } else if (this.justification.approved == true) {
        type = 'primary'
      } else if (this.justification.approved == false) {
        type = 'light-danger'
      }
      return type
    }
  },

  created() {
    this.maxDate = new Date()
    this.getJustificationData()
  },

  methods: {
    setJustificationData(justification) {
      console.log('setJustificationData :>> ', justification)
      if (!justification) return

      const tmpDates = []
      let startTime = null
      let endTime = null
      if (justification.dates && justification.dates.length) {
        justification.dates.forEach(date => {
          tmpDates.push({
            start_at: date.start_at ? new Date(date.start_at) : null,
            end_at: date.end_at ? new Date(date.end_at) : null
          })
        })

        if (justification.start_time) {
          startTime = new Date(`${justification.dates[0].start_at} ${justification.start_time}`)
        }

        if (justification.end_time) {
          endTime = new Date(`${justification.dates[0].start_at} ${justification.end_time}`)
        }
      } else {
        startTime = justification.start_time ? Dates.from(justification.start_time).toDate() : null
        endTime = justification.end_time ? Dates.from(justification.end_time).toDate() : null
      }

      if (!tmpDates.length) {
        tmpDates.push({
          start_at: new Date(),
          end_at: new Date()
        })
      }

      const tmpJ = {
        key: this.justificationKey,
        dates: tmpDates,
        start_time: startTime,
        end_time: endTime,
        death_date: justification.death_date ? new Date(justification.death_date) : null
      }
      this.justification = Object.assign({}, justification, tmpJ)
    },

    setValidationData(validations) {
      if (!validations) return
      this.canComment = validations.can_comment
      this.canEdit = validations.can_edit
      this.canSubmit = validations.can_submit
      this.canDelete = validations.can_edit
    },

    async getJustificationData() {
      this.loading = true

      try {
        const response = await this.api.getUserJustification(this.justificationKey, {
          with_validations: true
        })
        console.log('response :>> ', response)
        this.absence = response.absence
        this.users = response.users
        this.comments = response.comments
        this.setJustificationData(response.justification)
        this.setValidationData(response.validations)
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('error :>> ', errorKey, error)
        if (errorKey) {
          if (errorKey === 'NotFound') return this.$router.push({ name: 'error' })
          else if (errorKey === 'Forbidden' || errorKey === 'Unauthorized')
            return this.$router.push({ name: 'forbidden' })
        }
      }

      await utils.sleep(250)
      this.loading = false
    },

    async saveJustification(justification) {
      this.savingData = true
      this.savingDataError = false

      const tmpJustf = JSON.parse(JSON.stringify(justification))
      delete tmpJustf.status
      tmpJustf.files = justification.files.map(el => el.key)
      tmpJustf.dates = []
      justification.dates.forEach(date => {
        tmpJustf.dates.push({
          start_at: Dates.buildCore(date.start_at).format('YYYY-MM-DD'),
          end_at: Dates.buildCore(date.end_at).format('YYYY-MM-DD')
        })
      })

      tmpJustf.death_date = tmpJustf.death_date ? Dates.buildCore(tmpJustf.death_date).format('YYYY-MM-DD') : null
      tmpJustf.start_time = tmpJustf.start_time ? Dates.buildCore(tmpJustf.start_time).format('HH:mm') : null
      tmpJustf.end_time = tmpJustf.end_time ? Dates.buildCore(tmpJustf.end_time).format('HH:mm') : null

      try {
        const response = await this.api.editUserJustification(this.justificationKey, tmpJustf)
        console.log('response :>> ', response)
        this.absence = response.absence
        this.users = response.users
        this.setJustificationData(response.justification)
        this.setValidationData(response.validations)
      } catch (error) {
        console.log('error :>> ', error)
        this.savingDataError = true
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao tentar guardar a justificação',
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false
        })
      }

      this.savingData = false
    },

    confirmDeleteJustification() {
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar justificação',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>eliminar</strong> esta justificação?
        Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.deleteJustification()
        }
      })
    },

    async deleteJustification() {
      if (!this.canDelete) return

      try {
        await this.api.deleteUserJustification(this.justificationKey)
        this.$buefy.snackbar.open({
          message: `Justificação eliminada`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 2000,
          queue: true
        })
        this.$router.push({
          name: 'absences'
        })
      } catch (error) {
        console.error(`Deleting justification ${this.justificationKey}`, error)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao tentar eliminar a justificação',
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false
        })
      }
    },

    confirmSubmitJustification() {
      this.$buefy.dialog.confirm({
        confirmText: 'Submeter',
        cancelText: 'Cancelar',
        title: 'Submeter justificação',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>submeter</strong> esta justificação? ....</div>`, // TODO:Explicar o que vai acontecer.
        onConfirm: () => {
          this.submitJustification()
        }
      })
    },

    async submitJustification() {
      if (!this.canSubmit) return

      try {
        const response = await this.api.editUserJustificationStatus(this.justificationKey, { status: 'submitted' })
        this.absence = response.absence
        this.setJustificationData(response.justification)
        this.setValidationData(response.validations)
        this.$buefy.snackbar.open({
          message: `Justificação submetida`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 2000,
          queue: true
        })
      } catch (error) {
        console.error(`Submitting justification ${this.justificationKey}`, error)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao tentar submeter a justificação',
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false
        })
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "personalDetails": "Dados pessoais",
    "absenceJustification": "Justificação de ausência",
    "startDate": "Data de início",
    "endDate": "Data de fim",
    "startTime": "Hora de início",
    "endTime": "Hora de fim",
    "absenceMotive": "Motivo da falta",
    "uploadFiles": "Carregar ficheiros",
    "hasErrors": "Existem erros no formulário",
    "cancel": "Cancelar",
    "reopen": "Reabrir",
    "submit": "Submeter",
    "save": "Guardar",
    "motives": {
      "wedding": "Casamento",
      "deathOfRelativeOrKin": "Falecimento de familiar ou afins",
      "nonAttributableFacts": "Factos não imputáveis ao/à trabalhador/a",
      "medical": "Pela necessidade de tratamento ambulatório, realização de consultas médicas ou exames complementares de diagnóstico",
      "educationalSituationOfMinor": "Deslocação à escola para se inteirar da situação educativa de filho/a menor",
      "firstDayOfSchool": " Acompanhamento de filho/a menor de 12 anos no primeiro dia de escola",
      "bloodDonationFirstAid": "Doação de sangue e socorrismo",
      "procedure": "Necessidade de submissão a métodos de seleção em procedimento concursal",
      "electedWorkers": "Trabalhadores/as eleitos/as para estruturas de representação coletiva",
      "publicOfficeCandidate": "Candidatos/as a eleições para cargos públicos durante o período legal da campanha eleitoral",
      "prenatalConsultations": "Dispensa para consultas pré-natais",
      "timeCreditUse": "Gozo de crédito de horas do mês anterior",
      "externalService": "Serviço externo no(s) dia(s)",
      "occasionalInterruptions": "Interrupções ocasionais",
      "annualCredit": "Crédito Anual",
      "other": "Outras faltas previstas na Lei"
    },
    "status": {
      "label": "Estado",
      "draft": "Rascunho",
      "submitted": "Submetida",
      "approved": "Aprovada",
      "evaluated": "Avaliada",
      "denied": "Rejeitada",
      "reviewed": "Revisto",
      "manager_reviewed": "Revisto pelo responsável",
      "closed": "Fechada"
    },
    "errors": {
      "required": {
        "motive": "Insira o motivo da falta"
      },
      "invalid": {
        "date": "Data inválida",
        "time": "Hora inválida"
      }
    }
  },
  "en": {
    "personalDetails": "Personal details",
    "absenceJustification": "Absence justification",
    "startDate": "Start date",
    "endDate": "End date",
    "startTime": "Start time",
    "endTime": "End time",
    "absenceMotive": "Reason for absence",
    "uploadFiles": "Upload files",
    "hasErrors": "There are errors in the form",
    "cancel": "Cancel",
    "reopen": "Reopen",
    "submit": "Submit",
    "save": "Save",
    "motives": {
      "wedding": "Wedding",
      "deathOfRelativeOrKin": "Death of relative or kin",
      "nonAttributableFacts": "Factors not attributable to the employee",
      "medical": "For the need for outpatient treatment, medical appointments, or complementary diagnostic tests",
      "educationalSituationOfMinor": "Trip to the school to find out about the educational situation of a minor child",
      "firstDayOfSchool": "Accompaniment of a child under 12 on the first day of school",
      "bloodDonationFirstAid": "Blood donation and first aid",
      "procedure": "Need to submit to selection methods in a competitive bidding procedure",
      "electedWorkers": "Workers elected to collective representation structures",
      "publicOfficeCandidate": "Candidates for public office during the legal period of the election campaign",
      "prenatalConsultations": "Exemption for prenatal consultations",
      "timeCreditUse": "Use of time credit from the previous month",
      "externalService": "External service on the day(s)",
      "occasionalInterruptions": "Occasional interruptions",
      "annualCredit": "Annual Credit",
      "other": "Other absences foreseen in the law"
    },
    "status": {
      "label": "Status",
      "draft": "Draft",
      "submitted": "Submitted",
      "approved": "Approved",
      "evaluated": "Evaluated",
      "denied": "Rejected",
      "reviewed": "Reviewed",
      "manager_reviewed": "Reviewed by manager",
      "closed": "Closed"
    },
    "errors": {
      "required": {
        "motive": "Enter reason for absence"
      },
      "invalid": {
        "date": "Invalid date",
        "time": "Invalid time"
      }
    }
  }
}
</i18n>

<style lang="postcss">
.update-modal-uploader .file-uploads {
  @apply w-full;
}

.fw-select .select {
  @apply rounded shadow w-full;
}
.fw-select .select select {
  @apply w-full;
}
.fw-select .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select:hover .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}

.fw-select:hover .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}
.fw-select:hover .select select {
  @apply border border-gray-200;
}
.fw-select .select select {
  @apply border border-gray-200;
}
.fw-select .select:not(.is-multiple) {
  height: 2.5rem;
}
.fw-select .select select {
  height: 2.5rem;
  padding-bottom: 0px;
  padding-top: 0px;
}

.fw-select .select select:focus {
  border-color: transparent;
}

.dropdown-trigger .control.has-icons-left .icon {
  top: 1px;
  height: 2.5em;
  width: 2.5em;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
</style>
